
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,700;1,600&display=swap');

*{
  font-family: 'Montserrat', sans-serif;
}


body{
  display: flexbox;
}

::-webkit-scrollbar {
  width: 10px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f16e; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;   
}
