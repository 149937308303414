.dropdown-container {
  text-align: left;
  border: 1px solid #B9B9B9;
  border-radius: 0 5px 5px 0;
  font-size: 14px;
  position: relative;
  z-index: 9;
}

.dropdown-input {
  height: 44px;
  padding: 0 10px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.dropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #CCC;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #FFF;
}

.dropdown-item {
    padding: 10px 10px 10px 25px;
    cursor: pointer;
    font-size: 14px;
}

.dropdown-item:hover {
    background-color: #eefacb;
}

.dropdown-item.selected {
    background-color: #97B43C;
    color: #FFF;   
}