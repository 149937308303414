@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,700;1,600&display=swap);
*{
  display: flexbox;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flexbox;
  flex:1 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
  font-family: 'Montserrat', sans-serif;
}


body{
  display: flexbox;
}

::-webkit-scrollbar {
  width: 10px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f16e; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;   
}

.dropdown-container {
  text-align: left;
  border: 1px solid #B9B9B9;
  border-radius: 0 5px 5px 0;
  font-size: 14px;
  position: relative;
  z-index: 9;
}

.dropdown-input {
  height: 44px;
  padding: 0 10px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.dropdown-menu {
    position: absolute;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    width: 100%;
    border: 1px solid #CCC;
    border-radius: 5px;
    overflow: auto;
    max-height: 150px;
    background-color: #FFF;
}

.dropdown-item {
    padding: 10px 10px 10px 25px;
    cursor: pointer;
    font-size: 14px;
}

.dropdown-item:hover {
    background-color: #eefacb;
}

.dropdown-item.selected {
    background-color: #97B43C;
    color: #FFF;   
}
